
import { record } from 'rrweb'
import { eventWithTime } from '@rrweb/types'
import { current_user } from '@/store/auth'
import { watch } from 'vue'

if (import.meta.env.PROD && import.meta.env.VITE_SCREEN_RECORDER_ENABLED === 'true') {    
   
    const session_id = crypto.randomUUID()
    let events: eventWithTime[] = []
    
    watch(current_user, async () => {
        if (!current_user.value) {
            return
        }
    
        const body = JSON.stringify({
            session_id,
            key: 'user',
            value: current_user.value
        })
    
        await fetch(`${import.meta.env.VITE_SCREEN_RECORDER_ENDPOINT}/api/v1/sessions/meta-data`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${import.meta.env.VITE_SCREEN_RECORDER_KEY}`
            },
            body
        })
    })
    
    let timer: number | null = null
    
    const saveEvents = async () => {
        if (timer) {
            window.clearTimeout(timer)
        }
        timer = window.setTimeout(() => {
            const body = JSON.stringify({ events, session_id })
            events = []
    
            fetch(`${import.meta.env.VITE_SCREEN_RECORDER_ENDPOINT}/api/v1/events`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${import.meta.env.VITE_SCREEN_RECORDER_KEY}`
                },
                body
            })
    
        }, 2000)
    }
    
    
    
    record({
        emit(event, is_checkout) {
            events.push(event)
    
            saveEvents()
        },
        //checkoutEveryNms: 30000
    })
    
}

